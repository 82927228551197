import { render, staticRenderFns } from "./market.vue?vue&type=template&id=522202ab&scoped=true"
import script from "./market.vue?vue&type=script&lang=js"
export * from "./market.vue?vue&type=script&lang=js"
import style0 from "./market.vue?vue&type=style&index=0&id=522202ab&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "522202ab",
  null
  
)

export default component.exports